@font-face {
  font-family: main;
  src: url('../public/earthly.ttf');
  font-weight: bold;
  font-size: 16px;
}

@font-face {
  font-family: skinnyFont;
  src: url('../public/rainyhearts.ttf');
}